import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import LanguageIcon from '@material-ui/icons/Language';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Our Toolbox = Your Toolbox</h2>
            <h5 className={classes.description}>
             CampOwl provides innovative, industry leading technology solutions for Campgrounds and RV Resorts. One call for all
             your technology solutions. Services include hardware, networks, wireless mesh, websites, and more.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Management Software"
                description="Our state of the art Reservation Management System is on its way, currently in Beta Testing"
                icon={DesktopWindowsIcon}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Managed Network/Hardware"
                description="Vacation easy with managed technology solutions to solve problems before they happen"
                icon={VerifiedUser}
                iconColor="success"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Web Design/Hosting"
                description="Blazing fast websites/hosting, todays era parks are selling 30-80% of reservations online,"
                icon={LanguageIcon}
                iconColor="danger"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
